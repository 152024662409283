import iconCrazyGamesLargePurple from '../images/platforms/crazy-games-large-purple-icon.svg';
import { isMobile } from './utils';

export const gamesCommon = {
    chess: {
        title: 'title/chess',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                path: '228794?utm_source=refferal_program&utm_medium=delank-myhd&clid=2968864',
            },
            crazyGames: {
                path: 'chess-free',
            },
            ok: {
                path: 'chess-free',
            },
            vk: {
                path: 'app51798267',
            },
            rustore: {
                path: 'com.smartberry.chess',
            },
            appGallery: {
                path: 'C111554883',
            },
            googlePlay: {
                path: 'com.smartberry.chess',
            },
            playdeck: {
                path: 'eyJnYW1lIjp7ImtleSI6InhFWENHY0RsVGkifX0=',
            },
            gameMonetize: {
                path: 'chess-free-game',
            },
            gamePix: {
                path: 'chess-for-free',
            },
            wgPlayground: {
                path: 'gamepush/chess-free',
            },
            pikabu: {
                path: 'shakhmatyi-ru'
            },
            // poki: {
            //     path: 'chess-free',
            // },
        },
    },
    checkersRu: {
        title: 'title/checkersRu',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                path: '364458?utm_source=refferal_program&utm_medium=delank-myhd&clid=10371474',
            },
            crazyGames: {
                path: 'russian-checkers-free',
            },
            ok: {
                path: '512002818335',
            },
            vk: {
                path: 'app52172230',
            },
            rustore: {
                path: 'com.smartberry.checkers_ru',
            },
            appGallery: {
                path: 'C112205949',
            },
            googlePlay: {
                path: 'com.smartberry.checkers_ru',
            },
            playdeck: {
                path: 'eyJnYW1lIjp7ImtleSI6ImpiR3NEOVZwZXoifX0=',
            },
            gameMonetize: {
                path: 'russian-checkers-game',
            },
            gamePix: {
                path: 'russian-checkers',
            },
            wgPlayground: {
                path: 'gamepush/russian-checkers',
            },
            pikabu: {
                path: 'shashki-ru',
            },
        },
    },
    checkersPoddavki: {
        title: 'title/poddavki',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                path: '368846?utm_source=refferal_program&utm_medium=delank-myhd&clid=10788576',
            },
            ok: {
                path: 'poddavkiru',
            },
            vk: {
                path: 'app52339827',
            },
            rustore: {
                path: 'com.smartberry.poddavki',
            },
            appGallery: {
                path: 'C112207905',
            },
            googlePlay: {
                path: 'com.smartberry.poddavki',
            },
            playdeck: {
                path: 'eyJnYW1lIjp7ImtleSI6IkxoU1pXVllCc24ifX0=',
            },
            gameMonetize: {
                path: 'giveaway-checkers-game',
            },
            gamePix: {
                path: 'giveaway-checkers',
            },
            wgPlayground: {
                path: 'gamepush/giveaway-checkers',
            },
            pikabu: {
                path: 'poddavki-ru',
            },
        },
    },
    checkersEn: {
        title: 'title/checkersEn',
        mainPlatformCode: 'crazyGames',
        platforms: {
            crazyGames: {
                icon: iconCrazyGamesLargePurple,
                path: 'checkers-free',
            },
        },
    },
    saper: {
        title: 'title/saper',
        mainPlatformCode: 'crazyGames',
        platforms: {
            crazyGames: {
                icon: iconCrazyGamesLargePurple,
                path: 'minesweeper-duel',
            },
            yandexGames: {
                path: '396467?utm_source=refferal_program&utm_medium=delank-myhd&clid=10788576',
            },
            ok: {
                path: 'saperduel',
            },
            vk: {
                path: 'app52860603',
            },
            rustore: {
                path: 'com.smartberry.minesweeper_duel',
            },
            // appGallery: {
            //     path: 'C112207905',
            // },
            googlePlay: {
                path: 'com.smartberry.minesweeper_duel',
            },
            // playdeck: {
            //     path: 'eyJnYW1lIjp7ImtleSI6IkxoU1pXVllCc24ifX0=',
            // },
            gameMonetize: {
                path: 'minesweeper-duel-game',
            },
            // gamePix: {
            //     path: 'giveaway-checkers',
            // },
            wgPlayground: {
                path: 'smartberry/minesweeper-duel',
            },
        },
    },
    petsAndPaints: {
        title: 'title/petsAndPaints',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                path: '225080?utm_source=refferal_program&utm_medium=delank-myhd&clid=9491731',
            },
            crazyGames: {
                path: 'timberland-arrange-puzzle-game',
            },
            vk: {
                path: 'app52094267',
            },
            ok: {
                path: '512001416278',
            },
            playdeck: {
                path: 'eyJnYW1lIjp7ImtleSI6IjNxV0VBaVo5QjUifX0='
            },
            gameMonetize: {
                path: 'timberland-arrange-puzzle-game-game',
            },
            gameDistribution: {
                path: '89b674adbc344c52b3e92fa0e435aacc/',
            },
            rustore: {
                path: 'com.delank.timberland',
            },
            googlePlay: {
                path: 'com.delank.timberland',
            },
            appStore: {
                path: 'timberland-puzzle-game/id1563556047',
            },
            appGallery: {
                path: 'C107157987',
            },
            nashStore: {
                path: '62864512fb3ed3501d52cfc0',
            },
        },
    },
    marketplace: {
        title: 'title/marketplace',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                path: '337436?utm_source=refferal_program&utm_medium=delank-myhd&clid=9491668',
            },
            vk: {
                path: 'app52018804',
            },
        },
    },
    retroRacerTurbo: {
        title: 'title/retroRacerTurbo',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                path: '264148?utm_source=refferal_program&utm_medium=delank-myhd',
            },
        },
    },
}
