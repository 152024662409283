import chessRuCover from '../images/games/chess-ru-cover.webp';
import checkersEnCover from '../images/games/checkers-en-cover.webp';
import checkersRuCoverWithRuTitle from '../images/games/checkers-ru-cover-with-ru-title.webp';
import poddavkiRuCover from '../images/games/poddavki-ru-title.webp';
import petsAndPaintsCover from '../images/games/pets-and-paints-cover.ru.webp';
import saperDuelCover from '../images/games/saper-cover-purpur.ru.webp';
import marketplaceCover from '../images/games/marketplace-cover.webp';
import retroRacerTurboCover from '../images/games/retro-racer-turbo-cover.webp';
import { gamesCommon } from './gamesCommon';

export const games = {
    chess: {
        ...gamesCommon.chess,
        logo: chessRuCover,
    },
    checkersRu: {
        ...gamesCommon.checkersRu,
        logo: checkersRuCoverWithRuTitle,
    },
    checkersPoddavki: {
        ...gamesCommon.checkersPoddavki,
        logo: poddavkiRuCover,
    },
    checkersEn: {
        ...gamesCommon.checkersEn,
        logo: checkersEnCover,
    },
    saper: {
        ...gamesCommon.saper,
        logo: saperDuelCover,
    },
    petsAndPaints: {
        ...gamesCommon.petsAndPaints,
        logo: petsAndPaintsCover,
    },
    marketplace: {
        ...gamesCommon.marketplace,
        logo: marketplaceCover,
    },
    retroRacerTurbo: {
        ...gamesCommon.retroRacerTurbo,
        logo: retroRacerTurboCover,
    },
}